import React, { FormEvent, useState, ChangeEvent } from 'react';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Box, Button, Divider, Typography, styled } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const stripePromise = loadStripe('pk_test_51OlKnUD9RbOIQf5WWrEt9UuTCqaZCjOPcdzN3DEHBFUyNTRwWLeBhQoo67JFXlKYtu2L4yk9L6F0ofzLTjZsKRpH00WiywtuIB');

const webStyle = {
  continueButton: {
    backgroundColor: "#8CA74B",
    borderRadius: '8px',
    height: '56px',
    width: '240px',
    marginTop: '32px',
  },
  continueText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  cancleButton: {
    borderRadius: '8px',
    height: '56px',
    width: '195px',
    marginTop: '32px',
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
  },
  cancelText: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#8CA74B',
    textTransform: 'none',
  },
  titleTexts: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110"
  },
  titleTexts2: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    marginTop:10
  },
};

interface CreditCardPaymentProps {
  handleCloseModal: () => void;
  paymentCallback: (paymentMethodId: string) => void;
  buttonText: string;
  creditCardText: string;
}

const validationSchema = Yup.object({
  cardHolderName: Yup.string().required('Cardholder name is required'),
});


const CustomErrorMessage = styled(Box)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "13px",
  color: 'red',
  marginTop: '5px',
})


const CreditCardPayment: React.FC<CreditCardPaymentProps> = ({ handleCloseModal, paymentCallback,buttonText,creditCardText }) => {
  const [cardHolderName, setCardHolderName] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');

  const handleCardHolderName = (e: ChangeEvent<HTMLInputElement>) => setCardHolderName(e.target.value);

  const getCardLogo = (brand: string) => {
    if (brand === 'visa') {
      return (
        <img
          src={require("./visa.png")}
          alt="Visa logo"
          style={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            width: '24px',
            height: '24px',
          }}
        />
      );
    } else if (brand === 'mastercard') {
      return (
        <img
          src={require("./masterCard.png")}
          alt="MasterCard logo"
          style={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            width: '24px',
            height: '24px',
          }}
        />
      );
    }
    return null;
  };

  const handleCardNumberChange = (event: any) => {
    if (event.brand) {
      setCardBrand(event.brand);
    }
  };

  
  const handleCardPayments = async (values: { cardHolderName: string }, stripe: Stripe | null, elements: StripeElements | null) => {
    if (!stripe || !elements) {
      console.error('Stripe.js not loaded');
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      console.error('Card element not found');
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: values.cardHolderName,
        },
      });

      if (error) {
        console.error('Error creating payment method:', error);
      } else if (paymentMethod) {
        console.log('Payment Method:', paymentMethod);
        paymentCallback(paymentMethod.id);
      } else {
        console.error('Payment method creation returned null or undefined');
      }
    } catch (error) {
      console.error('Exception creating payment method:', error);
    }
  };


  return (
    <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Formik
          initialValues={{ cardHolderName: '', cardNumber: '', cardExpiry: '', cardCvc: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleCardPayments(values, stripe, elements);
            actions.setSubmitting(false);
          }}
        >
          {({ handleChange, handleBlur, values, handleSubmit }) => (
           
           <form onSubmit={handleSubmit} style={{ marginTop: 30 }} data-test-id="handleCardPayment">
             <Typography style={{fontWeight:400, fontSize:16,fontFamily:"Poppins",color:"1C1917"}}>{creditCardText}</Typography>
              <Typography style={webStyle.titleTexts2}>Cardholder name</Typography>
              <div style={{ position: 'relative', marginBottom: '20px', marginTop: 5 }}>
                <Field
                  type="text"
                  name="cardHolderName"
                  style={{
                    width: '100%',
                    borderRadius: '4px',
                    padding: '20px 10px 16px 10px',
                    border: '1px solid #ced4da',
                    paddingRight: '40px',
                    outline: 'none',
                    fontWeight: 400,
                    fontSize: '16px',
                    fontFamily: 'Poppins',
                    color: "#000",
                  }}
                  autoComplete="off"
                  placeholder="Your cardholder name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cardHolderName}
                  data-test-id="inputSimulate"
                />

                <CustomErrorMessage>
                  <ErrorMessage name="cardHolderName" className="errorMessage" />
                </CustomErrorMessage>

                <img
                  src={require("./profile.png")}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    width: '24px',
                    height: '24px',
                  }}
                  alt="Profile"
                />
              </div>

              <Typography style={webStyle.titleTexts}>Card number</Typography>
              <div
                style={{
                  marginTop: '5px',
                  border: '1px solid #D6D3D1',
                  borderRadius: '4px',
                  padding: '20px 10px 16px 10px',
                  position: 'relative',
                }}
              >
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        '::placeholder': {
                          color: '#A8A29E',
                          fontSize: '16px',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                  onChange={handleCardNumberChange}
                  data-test-id="fillCardsDetailsTest"
                />
                {getCardLogo(cardBrand)}

              </div>


            

              <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop:20 }}>
                <div style={{ flex: '1', marginRight: '10px' }}>
                  <Typography style={webStyle.titleTexts}>Expiration date</Typography>
                  <div
                    style={{
                      marginTop: '5px',
                      border: '1px solid #D6D3D1',
                      borderRadius: '4px',
                      padding: '20px 10px 16px 10px',
                    }}
                  >
                    <CardExpiryElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#000',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            '::placeholder': {
                              color: '#A8A29E',
                              fontSize: '16px',
                            },
                            padding: '10px',
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />

                 
                  </div>
                  
                </div>
                
                <div style={{ flex: '1', marginLeft: '10px' }}>
                  <Typography style={webStyle.titleTexts}>Security code</Typography>
                  <div
                    style={{
                      marginTop: '5px',
                      border: '1px solid #D6D3D1',
                      borderRadius: '4px',
                      padding: '20px 10px 16px 10px',
                    }}
                  >
                    <CardCvcElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#000',
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            '::placeholder': {
                              color: '#A8A29E',
                              fontSize: '16px',
                            },
                            padding: '10px',
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />

                  </div>
                  
                </div>

                
              </Box>

              <Divider
                style={{
                  width: 'calc(100% + 90px)',
                  margin: '10px -44px',
                  marginTop:30
                }}
              />

              <Box style={{ display: 'flex', justifyContent: 'space-between',  marginTop:'-20px' }}>
                <Button style={webStyle.cancleButton} onClick={handleCloseModal}>
                  <Typography style={webStyle.cancelText as React.CSSProperties}>Cancel</Typography>
                </Button>

                <Button style={webStyle.continueButton} type="submit">
                <Typography style={webStyle.continueText as any}>{buttonText}</Typography>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </ElementsConsumer>
  </Elements>

  );
};

export default CreditCardPayment;
