export const trashIcon = require("../assets/group_trashcan.png");
export const avatar = require("../assets/avatar_.png");
export const categoryIcon = require("../assets/image_(2).png");
export const groupImg = require("../assets/image_(4).png");
export const photoExpandImg = require("../assets/Carousel_.png");
export const people1 = require("../assets/group_01.png");
export const people2 = require("../assets/Carousel_(2).png");
export const people3 = require("../assets/Carousel_(3).png");
export const videoBtn = require("../assets/videoBtn.png");
export const tree = require("../assets/group__.png");
export const trashcan = require("../assets/trashcan.png");
export const dropDownIcon = require("../assets/dropDownIcon.png");
export const reloadIcon = require("../assets/reloadIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const threeDotsIcon = require("../assets/threeDotsIcon.png");
export const folderOpenImage = require("../assets/folderOpenImage.png");
export const imageavtarone = require("../assets/imageavtarone.png");
export const trashIconDelete = require("../assets/trash.png");
export const orangeClose = require("../assets/orangeClose.png");
export const openFolder = require("../assets/openFolder.png");
export const downIcon = require("../assets/downIcon.png");
