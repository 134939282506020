import React from "react";

import {
    Box,
    Button,
    Typography,
    IconButton,
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    // Customizable Area Start
    Popover,
    TextField,
    Select,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { check, bankAccountWhiteLogo, bankAccountGreyLogo, creditCardWhiteLogo, creditCardGreyLogo, downIcon, iIcon } from "./assets";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import CloseIcon from '@mui/icons-material/Close';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import CreditCardPayment from "../../../components/src/CreditCardPayment";
import { Formik, Field, ErrorMessage } from 'formik';
import DOMPurify from 'dompurify';

// Customizable Area End

import SubscriptionbillingController, {
    Props,
} from "./SubscriptionbillingController";


// Customizable Area Start
const bankNames = [
    "Bank of America",
    "Chase",
    "Wells Fargo",
    "Citi",
    "Capital One",
    "PNC Bank",
    "US Bank",
    "TD Bank",
    "BB&T",
    "SunTrust",
    "Fifth Third Bank",
    "HSBC",
    "Charles Schwab",
    "Ally Bank",
    "KeyBank",
    "Regions Bank",
    "Santander Bank",
    "BMO Harris Bank",
    "Huntington Bank",
    "M&T Bank",
    "Citizens Bank",
    "American Express",
    "Navy Federal Credit Union",
    "USAA",
    "Synchrony Bank",
    "Comerica",
    "Zions Bank",
    "First Republic Bank",
    "Silicon Valley Bank",
    "Discover Bank",
    "Goldman Sachs Bank USA",
    "Morgan Stanley Bank",
    "TD Ameritrade",
    "E*TRADE Bank",
    "Barclays Bank",
    "BancorpSouth",
    "BOK Financial",
    "Frost Bank",
    "Bank of the West",
    "BMO Financial Group",
    "Webster Bank",
    "Old National Bank",
    "UMB Financial Corporation",
    "People's United Financial",
    "FNB Corporation",
    "Pinnacle Financial Partners",
    "First National Bank",
    "Valley National Bank",
    "City National Bank",
    "East West Bank",
    "Cullen/Frost Bankers",
    "Synovus Bank",
    "Bank OZK",
    "Prosperity Bank",
    "Texas Capital Bank",
    "Wintrust Financial",
    "South State Bank",
    "Independent Bank",
    "Renasant Bank",
    "BankUnited",
    "First Citizens Bank",
    "United Community Bank",
    "Cadence Bank",
    "Western Alliance Bank",
    "Banner Bank",
    "Columbia Bank",
    "Great Western Bank",
    "Pacific Premier Bank",
    "First Horizon Bank",
    "Central Bank",
    "Southwest Bank",
    "Commerce Bank",
    "TowneBank",
    "Heritage Bank",
    "Bank of Hawaii",
    "BancFirst",
    "Glacier Bank",
    "Simmons Bank",
    "UMB Bank",
    "Heartland Bank",
    "Tri Counties Bank",
    "Trustmark National Bank",
    "First Financial Bank",
    "First Interstate Bank",
    "Provident Bank",
    "City Bank",
    "Hanmi Bank",
    "Mechanics Bank",
    "NBT Bank",
    "OceanFirst Bank",
    "Old Second National Bank",
    "Park National Bank",
    "Peoples Bank",
    "Pinnacle Bank",
    "PlainsCapital Bank",
    "PNC Bank",
    "Popular Bank",
    "Rabobank",
    "Raymond James Bank",
    "Republic Bank",
    "Rockland Trust",
    "Santander Bank",
    "Scotiabank",
    "Simmons Bank",
    "Southern Bank",
    "Sterling National Bank",
    "Synovus Bank",
    "TCF Bank",
    "TD Bank",
    "The Northern Trust Company",
    "Trustmark Bank",
    "UMB Bank",
    "Union Bank",
    "United Bank",
    "Valley National Bank",
    "Webster Bank",
    "Wells Fargo",
    "Western Alliance Bank",
    "Wintrust Bank",
    "Zions Bank"
];
const states = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" }
];


const cities = [
    { value: "NYC", label: "New York City" },
    { value: "LA", label: "Los Angeles" },
    { value: "Chicago", label: "Chicago" },
    { value: "Houston", label: "Houston" },
    { value: "Phoenix", label: "Phoenix" },
    { value: "Philadelphia", label: "Philadelphia" },
    { value: "San Antonio", label: "San Antonio" },
    { value: "San Diego", label: "San Diego" },
    { value: "Dallas", label: "Dallas" },
    { value: "San Jose", label: "San Jose" },
    { value: "Austin", label: "Austin" },
    { value: "Jacksonville", label: "Jacksonville" },
    { value: "Fort Worth", label: "Fort Worth" },
    { value: "Columbus", label: "Columbus" },
    { value: "San Francisco", label: "San Francisco" },
    { value: "Charlotte", label: "Charlotte" },
    { value: "Indianapolis", label: "Indianapolis" },
    { value: "Seattle", label: "Seattle" },
    { value: "Denver", label: "Denver" },
    { value: "Washington", label: "Washington" },
    { value: "Boston", label: "Boston" },
    { value: "El Paso", label: "El Paso" },
    { value: "Nashville", label: "Nashville" },
    { value: "Detroit", label: "Detroit" },
    { value: "Oklahoma City", label: "Oklahoma City" },
    { value: "Portland", label: "Portland" },
    { value: "Las Vegas", label: "Las Vegas" },
    { value: "Memphis", label: "Memphis" },
    { value: "Louisville", label: "Louisville" },
    { value: "Baltimore", label: "Baltimore" },
    { value: "Milwaukee", label: "Milwaukee" },
    { value: "Albuquerque", label: "Albuquerque" },
    { value: "Tucson", label: "Tucson" },
    { value: "Fresno", label: "Fresno" },
    { value: "Sacramento", label: "Sacramento" },
    { value: "Mesa", label: "Mesa" },
    { value: "Atlanta", label: "Atlanta" },
    { value: "Kansas City", label: "Kansas City" },
    { value: "Colorado Springs", label: "Colorado Springs" },
    { value: "Miami", label: "Miami" },
    { value: "Raleigh", label: "Raleigh" },
    { value: "Omaha", label: "Omaha" },
    { value: "Long Beach", label: "Long Beach" },
    { value: "Virginia Beach", label: "Virginia Beach" },
    { value: "Oakland", label: "Oakland" },
    { value: "Minneapolis", label: "Minneapolis" },
    { value: "Tampa", label: "Tampa" },
    { value: "Tulsa", label: "Tulsa" },
    { value: "Arlington", label: "Arlington" },
    { value: "New Orleans", label: "New Orleans" }
];

// Customizable Area End

export default class PaymentOptions extends SubscriptionbillingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <>
                <Box>
                    <HeaderDropdown data-test-id="header" navigation={this.props.navigation} id={""}/>
                </Box>
                <Box style={{ display: "flex",  justifyContent: "center", gap:"40px"}}>
                    <Box>
                        <Card
                            style={{
                                width: "519px",
                                height: "277px",
                                borderRadius: '8px 8px 40px 8px',
                                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
                                padding: "16px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginTop: 50
                            }}
                        >
                            <CardContent>
                                <Typography
                                    style={{ fontSize: 20, fontWeight: 700, fontFamily: "Poppins" }}
                                >
                                    Plan & Pricing
                                </Typography>
                                <Box style={{
                                    borderRadius: '8px',
                                    border: '1px solid #D6D3D1',
                                    padding: 20,
                                    marginTop: 10
                                }}>
                                    <Typography style={{
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#1C2110",
                                        marginBottom: '5px'
                                    }}>{this.state.getData.name}</Typography>
                                    <Typography style={{
                                        marginBottom: 1.5,
                                        color: '#78716C',
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                    }}
                                    >
                                    {`The optimal plan for ${this.state.getData.name} projects`}
                                    </Typography>
                                    <Box style={{ display: "flex" }}>
                                        <Typography style={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1C2110",
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginBottom: '8px',
                                            marginTop: 10
                                        }}><img src={check} style={{ marginRight: '8px' }} />{this.state.getData.storage}</Typography>
                                        <Typography style={{
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1C2110",
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginBottom: '8px',
                                            marginTop: 10,
                                            marginLeft: 5
                                        }}>{this.state.getData.storage_unit} of storage </Typography>
                                    </Box>
                                    <Typography style={{
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        fontSize: "30px",
                                        lineHeight: "40px",
                                        color: '#1C2110',
                                        letterSpacing: '-1.5%',
                                        display: "flex",
                                        justifyContent: "end"
                                    }}>${this.state.getData.price} <span style={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        color: '#78716C',
                                        marginTop: 10,
                                        marginLeft: 5
                                    }}>/month</span></Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    {this.state.showPaymentMethod == "1" &&  
                    <BillingStyle>
                        <Box>
                            <Card style={{
                                width: "494px",
                                height: "700px",
                                borderRadius: '8px 8px 40px 8px',
                                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
                                padding: "16px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginTop: 50,
                                marginBottom: 100
                            }}>
                                <CardContent>
                                    <Typography
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 700,
                                            fontFamily: "Poppins",
                                            color: "#1C2110",
                                        }}
                                    >
                                        Billing Address
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: "Poppins",
                                            color: "#5D6063",
                                        }}
                                    >
                                        Designated location associated with your financial account, used for
                                        invoicing and payment purposes.
                                    </Typography>
                                    <Box display="flex" alignItems="center" flexDirection='row'>
                                        <img
                                            src={iIcon}
                                            style={{ width: 20, height: 20, marginTop: 35, cursor: 'pointer' }}
                                            data-test-id="modelopenPopTestId"
                                            onClick={this.handlePopoverOpen}
                                            alt="icon"
                                        />
                                        <Popover
                                            open={this.state.anchorEl}
                                            anchorEl={this.state.anchorEl}
                                            onClose={this.handlePopoverClose}
                                            data-test-id="modelclosePop"
                                            anchorOrigin={{
                                                horizontal: 'right',
                                                vertical: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'left',
                                            }}
                                            PaperProps={{
                                                style: {
                                                  transform: 'translateX(10px)',
                                                },
                                              }}
                                            disableRestoreFocus
                                        >
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '16px',
                                                    padding: '2px 8px',
                                                    backgroundColor: "#f7f0f0",
                                                    fontFamily: 'Arial, sans-serif',
                                                    borderLeft: '5px solid #8CA74B',
                                                    borderRadius: '5px',
                                                    maxWidth: '400px',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                }}
                                            >
                                                <Typography variant="body1" id="custom-dialog-description" style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                                                    Currently we only accept payments of US
                                                </Typography>
                                                <IconButton style={{ marginLeft: 'auto' }} onClick={this.handlePopoverClose}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        </Popover>
                                    </Box>

                                    <Formik
                                        data-test-id="formikPaymentOption"
                                        enableReinitialize
                                        initialValues={{
                                            fullName: this.state.fullName,
                                            address: this.state.address,
                                            city: this.state.city,
                                            state: this.state.state,
                                            zipCode: this.state.zipCode,
                                        }}
                                        validationSchema={this.BillingAddressSchema}
                                        onSubmit={async (values, { setSubmitting }) => {
                                        this.billingAddressApi(values);                                    
                                        setSubmitting(false);
                                    }
                                
                                }
                                    >
                                        {({ handleSubmit, handleChange, values, setFieldValue }) => (
                                            <form className="formContainer" onSubmit={handleSubmit}>

                                                <Box style={{ marginTop: 40 }}>

                                                    <Typography style={webStyle.titleTexts}>Full Name</Typography>
                                                    <TextField
                                                        data-test-id="fullNameField"
                                                        type="text"
                                                        style={{ marginBottom: '20px' }}
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="fullName"
                                                        placeholder="Your Full Name"
                                                        variant="outlined"
                                                        value={values.fullName}
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage name="fullName" component="div" className="errorMessage" />
                                                    <Typography style={webStyle.titleTexts}>Address</Typography>
                                                    <TextField
                                                         value={values.address}
                                                         onChange={handleChange}
                                                         data-test-id="address"
                                                         type="text"
                                                         style={{ marginBottom: '20px' }}
                                                         className="placeholderTexts"
                                                         autoComplete="off"
                                                         name="address"
                                                         placeholder="Your Address"
                                                         variant="outlined"
                                                         fullWidth
                                                    />
                                                    <ErrorMessage name="address" component="div" className="errorMessage" />

                                                    <Typography style={webStyle.titleTextsCity}>City</Typography>
                                                    <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                                        <Select
                                                            data-test-id="city"
                                                            value={values.city}
                                                            onChange={handleChange}
                                                            name="city"
                                                            className="placeholderTexts"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled>Select a city</MenuItem>
                                                            {cities.map((city) => (
                                                                <MenuItem key={city.value} value={city.value}>
                                                                    {city.label}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                    <ErrorMessage name="city" component="div" className="errorMessage" />

                                                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Box style={{ width: '230px' }}>
                                                            <Typography style={webStyle.titleTexts}> State</Typography>
                                                            <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                                                <Select
                                                                    value={values.state}
                                                                    onChange={handleChange}
                                                                    name="state"
                                                                    data-test-id="state"
                                                                    className="placeholderTexts"
                                                                    variant="outlined"
                                                                    autoComplete="off"
                                                                    displayEmpty
                                                                >
                                                                    <MenuItem value="" disabled>Select a state</MenuItem>
                                                                    {states.map((state) => (
                                                                        <MenuItem key={state.value} value={state.value}>
                                                                            {state.label}
                                                                        </MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl>
                                                            <ErrorMessage name="state" component="div" className="errorMessage" />
                                                        </Box>
                                                        <Box style={{ marginLeft: 20 }}>
                                                            <Typography style={webStyle.titleTexts}>Zip code</Typography>
                                                            <TextField
                                                                data-test-id="zipCodeField"
                                                                value={values.zipCode}
                                                                onChange={handleChange}
                                                                style={{ marginBottom: '20px' }}
                                                                autoComplete="off"
                                                                name="zipCode"
                                                                placeholder="Your Zip code"
                                                                variant="outlined"
                                                            />
                                                            <ErrorMessage name="zipCode" component="div" className="errorMessage" />

                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button style={webStyle.continueButton2} data-test-id="ContinueButtonTest" type="submit">
                                                            <Typography style={webStyle.continueText as any}>Continue</Typography>
                                                        </Button>
                                                    </Box>

                                                </Box>
                                            </form>
                                        )}

                                    </Formik>
                                </CardContent>
                            </Card>
                        </Box>
                    </BillingStyle>}
                    {(this.state.showPaymentMethod == "2") && 
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box style={{
                            width: "519px", margin: "auto", marginTop: 50, maxHeight: '90vh',
                        }}>
                            <Card
                                style={{
                                    width: "580px",
                                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16)",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    marginBottom: 100,
                                    overflow: 'hidden',
                                    maxHeight: 'inherit',
                                }}
                            >
                                <CardContent style={{
                                    height: "100%",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    position: 'relative'
                                }}>
                                    <Container>
                                        <Box>
                                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110', marginTop: 10 }}>Payment method</Typography>

                                            </Box>
                                        </Box>
                                        <Divider style={{
                                            width: 'calc(100% + 90px)',
                                            margin: '10px -44px',
                                        }} />
                                        <Box style={{
                                            marginTop: 20,
                                            backgroundColor: "#f7f0f0",
                                            width: "100%",
                                            minWidth: 408,
                                            padding: 2,
                                            borderRadius: 40
                                        }}>
                                            <ToggleButtonGroup
                                                value={this.state.paymentMethod}
                                                onChange={this.handleToggleButton}
                                                exclusive
                                                fullWidth
                                                aria-label="payment method"
                                                data-test-id="toggleButton"
                                            >
                                                <ToggleButton
                                                    value="bankTransfer"
                                                    aria-label="bank transfer"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontFamily: 'Poppins',
                                                        fontSize: 16,
                                                        textTransform: 'none',
                                                        borderColor: '#f7f0f0',
                                                        color: this.state.paymentMethod === 'bankTransfer' ? '#FAFAF9' : '#78716C',
                                                        borderRadius: 30,
                                                        backgroundColor: this.state.paymentMethod === 'bankTransfer' ? '#8CA74B' : '#f7f0f0',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={this.state.paymentMethod === 'bankTransfer' ? bankAccountWhiteLogo : bankAccountGreyLogo}
                                                        alt="Bank Transfer"
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginRight: '8px',
                                                            backgroundColor: this.state.paymentMethod === 'bankTransfer' ? '#8CA74B' : '#f7f0f0',
                                                        }}
                                                    />
                                                    Bank transfer (ACH)

                                                </ToggleButton>
                                                <ToggleButton
                                                    value="creditCard"
                                                    aria-label="credit card"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontFamily: 'Poppins',
                                                        fontSize: 16,
                                                        borderRadius: 30,
                                                        textTransform: 'none',
                                                        borderColor: '#f7f0f0',
                                                        color: this.state.paymentMethod === 'creditCard' ? '#ffffff' : '#78716C',
                                                        backgroundColor: this.state.paymentMethod === 'creditCard' ? '#8CA74B' : '#f7f0f0',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={this.state.paymentMethod === 'creditCard' ? creditCardWhiteLogo : creditCardGreyLogo}
                                                        alt="Credit Card"
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginRight: '8px',
                                                            backgroundColor: this.state.paymentMethod === 'creditCard' ? '#8CA74B' : '#f7f0f0',
                                                        }}
                                                    />
                                                    Credit Card
                                                </ToggleButton>

                                            </ToggleButtonGroup>
                                        </Box>
                                        {this.state.paymentMethod === 'creditCard' && (
                                            <CreditCardPayment handleCloseModal={this.handleCloseModal}
                                                paymentCallback={(id: any) => {


                                                    this.createSubscriptionApiCall(id)

                                                }}
                                                buttonText="Purchase"
                                                creditCardText="Fill in this form with your credit card details.."
                                            />
                                        )}
                                        <Formik
                                            data-test-id="formikPaymentOption"
                                            enableReinitialize
                                            initialValues={{
                                                accountHolderName: this.state.accountHolderName,
                                                bankName: this.state.bankName,
                                                accountNumber: this.state.accountNumber,
                                                accountType: this.state.accountType,
                                                routingNumber: this.state.routingNumber,
                                            }}
                                            validationSchema={this.SubscriptionBillingAchSchema}
                                            onSubmit={async (values, { setSubmitting }) => {
                                                this.createSubscriptionAchApiCall();
                                                setSubmitting(false);
                                            }}
                                        >
                                            {({ handleSubmit, isSubmitting }) => (
                                                <form onSubmit={handleSubmit} >
                                                    {this.state.paymentMethod === 'bankTransfer' && (

                                                        <Box style={{ marginTop: 20 }}>
                                                            <Typography style={{ fontWeight: 400, fontSize: 16, fontFamily: "Poppins", color: "1C1917" }}>Fill in this form with your bank account details.</Typography>
                                                            <Typography style={webStyle.titleTexts2}>Full name</Typography>
                                                            <Field
                                                                type="text"
                                                                name="accountHolderName"
                                                                data-test-id="Holdername"
                                                                value={this.state.accountHolderName}
                                                                onChange={this.handleChange}
                                                                style={{
                                                                    width: '100%',
                                                                    borderRadius: '4px',
                                                                    padding: '20px 10px 16px 10px',
                                                                    border: '1px solid #ced4da',
                                                                    paddingRight: '40px',
                                                                    outline: 'none',
                                                                    fontWeight: 400,
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Poppins',
                                                                    color: 'rgb(164, 152, 143)',
                                                                }}
                                                                placeholder="Your Name"
                                                            />
                                                            <CustomErrorMessage>
                                                                <ErrorMessage name="accountHolderName" className="errorMessage" />
                                                            </CustomErrorMessage>

                                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                                <Typography style={webStyle.titleTexts}>Bank name</Typography>
                                                                <div style={{ position: 'relative', width: '100%' }}>
                                                                    <Field
                                                                        as="select"
                                                                        name="bankName"
                                                                        data-test-id="bankName"
                                                                        onChange={this.handleChange}
                                                                        style={{
                                                                            width: '100%',
                                                                            borderRadius: '4px',
                                                                            padding: '20px 10px 16px 10px',
                                                                            border: '1px solid #ced4da',
                                                                            paddingRight: '40px',
                                                                            outline: 'none',
                                                                            fontWeight: 400,
                                                                            fontSize: '17px',
                                                                            fontFamily: 'Poppins',
                                                                            color: '#A8A29E',
                                                                            backgroundColor: 'transparent',
                                                                            appearance: 'none',
                                                                            WebkitAppearance: 'none',
                                                                            MozAppearance: 'none',
                                                                        }}
                                                                    >
                                                                        <option value="">Select the bank name</option>
                                                                        {bankNames.map((bank, index) => (
                                                                            <option key={index} value={bank}>{bank}</option>
                                                                        ))}
                                                                    </Field>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        right: '10px',
                                                                        transform: 'translateY(-50%)',
                                                                        pointerEvents: 'none',
                                                                    }}>
                                                                        <img src={downIcon} style={{ width: 24, height: 24 }} />
                                                                    </div>
                                                                </div>
                                                                <CustomErrorMessage>
                                                                    <ErrorMessage name="bankName" className="errorMessage" />
                                                                </CustomErrorMessage>
                                                            </FormControl>

                                                            <Box display="flex" justifyContent="space-between" style={{ gap: 20 }}>
                                                                <div style={{ width: '48%' }}>
                                                                    <Typography style={{ ...webStyle.titleTexts, }}>Account number</Typography>
                                                                    <Field
                                                                        type="text"
                                                                        name="accountNumber"
                                                                        data-test-id="accountnumber"
                                                                        value={this.state.accountNumber}
                                                                        onChange={this.handleChange}
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '57px',
                                                                            borderRadius: '4px',
                                                                            padding: '20px 10px 16px 10px',
                                                                            border: '1px solid #ced4da',
                                                                            paddingRight: '40px',
                                                                            outline: 'none',
                                                                            fontWeight: 400,
                                                                            fontSize: '16px',
                                                                            fontFamily: 'Poppins',
                                                                            color: 'rgb(164, 152, 143)',
                                                                            marginTop: 3
                                                                        }}
                                                                        placeholder="Your Account Number"
                                                                    />
                                                                    <CustomErrorMessage>
                                                                        <ErrorMessage name="accountNumber" className="errorMessage" />
                                                                    </CustomErrorMessage>
                                                                </div>
                                                                <div style={{ width: '48%' }}>
                                                                    <Typography style={{ ...webStyle.titleTexts, marginBottom: -5 }}>Bank account type</Typography>
                                                                    <FormControl fullWidth variant="outlined" margin="normal" style={{ marginTop: 8 }}>
                                                                        <div style={{ position: 'relative', width: '100%' }}>
                                                                            <Field
                                                                                as="select"
                                                                                name="accountType"
                                                                                data-test-id="accounttype"
                                                                                value={this.state.accountType}
                                                                                onChange={this.handleChange}
                                                                                style={{
                                                                                    width: '100%',
                                                                                    borderRadius: '4px',
                                                                                    padding: '20px 10px 16px 10px',
                                                                                    border: '1px solid #ced4da',
                                                                                    paddingRight: '40px',
                                                                                    outline: 'none',
                                                                                    fontWeight: 400,
                                                                                    fontSize: '17px',
                                                                                    fontFamily: 'Poppins',
                                                                                    backgroundColor: 'transparent',
                                                                                    color: '#A8A29E',
                                                                                    appearance: 'none',
                                                                                    WebkitAppearance: 'none',
                                                                                    MozAppearance: 'none',
                                                                                }}
                                                                            >
                                                                                <option value="">Select account type</option>
                                                                                <option value="individual">Individual</option>
                                                                                <option value="company">Company</option>
                                                                            </Field>
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                right: '10px',
                                                                                transform: 'translateY(-50%)',
                                                                                pointerEvents: 'none',
                                                                            }}>
                                                                                <img src={downIcon} style={{ width: 24, height: 24 }} />
                                                                            </div>
                                                                        </div>

                                                                        <CustomErrorMessage>
                                                                            <ErrorMessage name="accountType" className="errorMessage" />
                                                                        </CustomErrorMessage>
                                                                    </FormControl>
                                                                </div>
                                                            </Box>

                                                            <Typography style={webStyle.titleTexts}>Routing number</Typography>
                                                            <Field
                                                                type="text"
                                                                name="routingNumber"
                                                                data-test-id="routingnumber"
                                                                value={this.state.routingNumber}
                                                                onChange={this.handleChange}
                                                                style={{
                                                                    width: '100%',
                                                                    borderRadius: '4px',
                                                                    padding: '20px 10px 16px 10px',
                                                                    border: '1px solid #ced4da',
                                                                    paddingRight: '40px',
                                                                    outline: 'none',
                                                                    fontWeight: 400,
                                                                    fontSize: '16px',
                                                                    fontFamily: 'Poppins',
                                                                    color: 'rgb(164, 152, 143)',
                                                                    marginTop: 8,
                                                                }}
                                                                placeholder="Your Routing Number"
                                                            />
                                                            <CustomErrorMessage>
                                                                <ErrorMessage name="routingNumber" className="errorMessage" />
                                                            </CustomErrorMessage>

                                                            <Divider style={{
                                                                width: 'calc(100% + 90px)',
                                                                margin: '10px -44px',
                                                                marginTop: 25,
                                                            }} />

                                                            <Box style={{display:'flex',justifyContent:'center', marginTop:25, gap:"20px"}}>
                                                                <Button style={webStyle.cancleButton} onClick={this.handleCloseModal}>
                                                                    <Typography style={webStyle.cancelText as any}>Cancel</Typography>
                                                                </Button>
                                                                <Button type="submit" style={webStyle.continueButton} disabled={isSubmitting}>
                                                                    <Typography style={webStyle.continueText as any}>Purchase</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </form>
                                            )}
                                        </Formik>
                                    </Container>

                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
    }
                </Box></>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    titleTexts2: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginTop: 10
    },
    titleTexts: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110"
    },
    titleTextsCity:{
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginTop:10
    },
    cancleButton: {
        borderRadius: '8px',
        height: '56px',
        width: '195px',
        border: '1px solid #C2E176',
        backgroundColor: '#F8FFE7',
    },
    cancelText: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#8CA74B',
        textTransform: 'none',
    },
    continueButton: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '195px',
    },
    continueButton2: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        maxWidth: '230px',
        width:"100%",
        marginTop: '32px',
    },
    continueText: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
}



const BillingStyle = styled(Box)(() => ({
    "& .errorMessage": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: '#DC2626',
        marginTop: '-12px'
    },

}))

const CustomErrorMessage = styled(Box)({
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    color: 'red',
    marginTop: '5px',
})
// Customizable Area End
