import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";

type FileType = "photo" | "video";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  authToken: any;
  showDropdown: boolean;
  isGroupsExpanded: boolean;
  page: number;
  totalPages: number;
  isPhotoExpanded: boolean;
  isPeopleExpanded: boolean,
  isMemoriesExpanded: boolean,
  isFamilyTreeExpanded: boolean,
  showDeleteModal: boolean;
  showRecoverModal: boolean;
  isOpen: boolean;
  selectedOption: string;
  noDataFound: string;
  selectedFolder: string;
  isDropdownOpen: boolean;
  selectType: string;
  selectedFileType: FileType;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  dropdownRef: React.RefObject<HTMLDivElement>;
  getTrashDataApiCallId: string = "";
  filterAscendingDataApiCallId: string = "";
  deleteAllDataFromTrashApiCallId: string = "";
  restoreSingleFileApiCallId: string = "";
  deleteSingleFileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      authToken: false,
      showDropdown: false,
      isGroupsExpanded: false,
      page: 1,
      totalPages: 2,
      isPhotoExpanded: false,
      isPeopleExpanded: false,
      isMemoriesExpanded: false,
      isFamilyTreeExpanded: false,
      showDeleteModal: false,
      showRecoverModal: false,
      isOpen: false,
      selectedOption: "Most recent",
      noDataFound: "",
      selectedFolder: 'Miami Beach',
      isDropdownOpen: false,
      selectType: "",
      selectedFileType: "photo"
    };
    this.dropdownRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.checkAuthToken();
    this.getBankSubscriptionApiCall();
    this.filterDataAscendingApiCall();
    document.addEventListener('mousedown', this.handleClickOutside);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if(webApiRequestCallId === this.getTrashDataApiCallId) {
          console.log("164 aygga aygga", webResponseJson);
          this.setState({noDataFound: webResponseJson});
        }

        else if(webApiRequestCallId === this.filterAscendingDataApiCallId){
          console.log("response 155 syd ajay", webResponseJson);          
        }

        else if(webApiRequestCallId === this.deleteAllDataFromTrashApiCallId){
          console.log("response 160", webResponseJson);
        }
        
        else if(webApiRequestCallId === this.restoreSingleFileApiCallId){
          console.log("response 166", webResponseJson);
        }
        else if(webApiRequestCallId === this.deleteSingleFileApiCallId){
          console.log("response 176", webResponseJson);
        }
        else if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  onLoginClick = () => {
    this.props.navigation.navigate("Login")
  }

  onRegisterClick = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  checkAuthToken = () => {
    const checkToken = localStorage.getItem("token");
    if (checkToken) {
      this.setState({ authToken: true });
    }
  }

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };


  handleGroupExpand = () => {
    this.setState(prevState => ({
      isGroupsExpanded: !prevState.isGroupsExpanded
    }));
  };
  handlePageChange = (event: null, value: number) => {
    this.setState({ page: value });
  };


  handlePhotoExpand = () => {
    this.setState(prevState => ({
      isPhotoExpanded: !prevState.isPhotoExpanded
    }));
  };

  handlePeopleExpand = () => {
    this.setState(prevState => ({
      isPeopleExpanded: !prevState.isPeopleExpanded
    }));
  };

  handleMemoriesExpand = () => {
    this.setState(prevState => ({
      isMemoriesExpanded: !prevState.isMemoriesExpanded
    }));
  };

  handleFamilyTreeExpand = () => {
    this.setState(prevState => ({
      isFamilyTreeExpanded: !prevState.isFamilyTreeExpanded
    }));
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({ showDropdown: false });
    }
  };

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  openModal = () => {
    this.setState({ showDeleteModal: true });
  };

  closeModal = () => {
    this.setState({ showDeleteModal: false });
  };

  openRecoverModal = () => {
    this.setState({ showRecoverModal: true });
  };

  closeRecoverModal = () => {
    this.setState({ showRecoverModal: false });
  };


  handleDeleteAll = () => {
    this.deleteAllDataFromTrashApiCall();
    this.closeModal();
    
  };

  handleRecover = () => {
    this.restoreSingleFileApiCall();
    this.closeModal();
  }


  toggleDropdownOpen = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleSelect = (option: string) => {
    this.setState({ selectedOption: option, isOpen: false });
  };

  getBankSubscriptionApiCall = () => {

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTrashDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getTrashDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterDataAscendingApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterAscendingDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getAscendingApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteAllDataFromTrashApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllDataFromTrashApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.deleteAllDataFromTrashApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteAllDataApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  restoreSingleFileApiCall = () => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.restoreSingleFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.restoreSingleDataFromTrashApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.restoreSingleDataApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChange = (event:any) => {
    const { value } = event.target;
    this.setState({
      selectType: value, 
    });
  };


  deleteSingleFileApiCall = (mediaType:any) => {
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: token
    };    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSingleFileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.deleteSingleFileApiEndPoint + `?media_type=${mediaType}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteAllDataApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMediaType = () => {
    const isVideo = this.state.selectedFileType === "video";
    return isVideo ? "video" : "photo";
  }

  // Customizable Area End
}
